.btn-div {
  display: flex;
  justify-content: flex-end;
  margin: 21px 0 0;
}

.time-div {
  display: flex;
  margin: 16px 0;
  font-size: 14px;

  .time {
    margin-left: 20px;
  }

  img {
    margin-bottom: -5px;
  }
}

.camera {
  margin: 0 auto;
  display: block;
  position: relative;
  top: 24px;
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 1px;
  border-radius: 100%;
  border: 1px solid #D9EAFF;
}

.loader-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 11111;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
