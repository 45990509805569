.pricing {
  
  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #459ADF !important;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .rbc-toolbar {
    display: none !important;
  }

  .rbc-time-header {
    .rbc-row:nth-child(2) {
      display: none;
    }
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid #D9EAFF !important;
    text-align: center;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #D9EAFF !important;
    min-height: 40px !important;
    border-bottom-left-radius: 3px;
    font-size: 12px;
    color: #71757A;
  }

  .rbc-time-slot {
    border: 0 !important;
    text-align: center;
  }

  .rbc-time-header > .rbc-row:first-of-type {
    border-bottom: none !important;
  }

  .rbc-time-view, .rbc-month-view, .rbc-agenda-view {
    border: 1px solid #EEEEEE !important;
    overflow: hidden;
    border-radius: 12px;
  }

  .rbc-overflowing {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #ffffff;
  }

  .rbc-time-content {
    background: #ffffff;
    border-top: 1px solid #D9EAFF !important;
  }

  .rbc-label {
    span {
      position: relative;
      top: 18px;
    }
  }

  .rbc-header {
    text-align: center !important;
    padding: 5px;
    border-bottom: 0 !important;

  }

  .rbc-today {
    background-color: transparent !important;
  }

  .rbc-row > .rbc-header.rbc-now > a > span {
    color: #71757A !important;
  }

  .rbc-slot-selecting {
    border-radius: 0 !important;
    // z-index: 0;

  }

  .rbc-event, .rbc-selected {
    // width: 100% !important;
    border-radius: 5px;

    .rbc-event-content {
      position: relative;
      text-transform: capitalize;

      top: 50%;
      transform: translateY(-10%);
      display: flex;
      justify-content: center;
    }
  }

  .rbc-day-slot .rbc-event-label, .rbc-allday-cell {
    display: none !important;
  }

  .rbc-slot-selection {
    background-color: #1E88E5 !important;
    border-radius: 4px !important;
    width: 100% !important;
    // z-index: 1;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0 !important;
  }

  .rbc-addons-dnd-resizable {
    text-align: center !important;
  }

  .icons {
    margin-top: -2px;
  }

  .paid {
    background-color: rgba(232, 245, 233, 1) !important;
  }

  .paid::before {
    content: "";
    background-color: #2CBC63;
    content: "";
  }

  .unpaid {
    background-color: #FBDBE0 !important;
  }

  .unpaid::before {
    content: "";
    background-color: #EB4962;
  }

   .tournament {
    background-color: #F2F5F7 !important;
  }
  .closed {
    background-color: rgba(255, 243, 224, 1) !important;
  }
  .event {
    background-color: rgba(237, 231, 246, 1) !important;
  }
  .maintenance {
    background-color: rgba(255, 253, 231, 1) !important;
  }
  .training {
    background-color: rgba(207, 216, 220, 1) !important;
  }
  .maintenance::before {
    content: "";
    background-color: rgba(255, 235, 59, 1);
  }

  .closed::before {
    content: "";
    background-color: rgba(255, 152, 0, 1);
  }

  .training::before {
    content: "";
    background-color: rgba(0, 0, 0, 1);
  }

  .event::before {
    content: "";
    background-color: rgba(103, 58, 183, 1);
  }

  .tournament::before {
    content: "";
    background-color: rgba(0, 188, 212, 1);
  }

  .paid, .unpaid, .cancelled, .maintenance, .closed, .training, .event, .tournament {
    border: 2px solid #ffffff !important;
    width: 100% !important;
    border-radius: 3px 4px 4px 3px;
    color: #263238 !important;
    border-left: 0 !important;
    border-top: 0 !important;
    padding-top: 1px;
    padding-left: 12px;

    .rbc-event-content {
      // text-transform: capitalize;
    }
  }

  .paid::before, .unpaid::before, .cancelled::before, .maintenance::before, .tournament::before, .event::before, .training::before, .closed::before {
    height: 100%;
    top: 0%;
    width: 7px;
    position: absolute;
    left: 0%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .leftPanelBig::before {
    width: 16px;
  }
  .leftPanelBig {
    padding-left: 21px;
  }
}

.pricingChanges {
  .rbc-event, .rbc-selected {
    border: 1px solid #ffffff !important;
    border-color: #ffffff !important;
    background-color: rgba($color: #1E88E5, $alpha: 1) !important;
  }
}

.schedule {
  
  .rbc-time-column {
    min-height: initial !important;
  }
   .rbc-time-view {
  // overflow-x: auto !important;
  //   overflow-y: hidden !important;
  // overflow-x: scroll !important;
  // overflow-y: hidden !important;
  // ::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   width: 7px;
  //   height: 7px;
  //   overflow-x: scroll !important;
  //   overflow-y: hidden !important;
  // }
  
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 4px;
  //   background-color: rgba(0, 0, 0, .5);
  //   box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  // }
}

// .rbc-time-content {
//   overflow: initial !important;
// }

.blurIt {
  filter: blur(2px);
  opacity: 0.3;
}
.rbc-time-gutter {
  box-shadow: 3px 1px 4px 1px rgba(0,0,0,0.1);
  // box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);
}
  .rbc-event-content {
    display: initial !important;
  }

  // .rbc-timeslot-group {
  //   //todo
  //   z-index: 1;
  //   transition: 0s linear background-color .05s;

  //   &:hover {
  //     background-color: #1E88E5 !important;
  //     border-radius: 3px 4px 4px 3px;
  //   }
  // }

  .disabled-time-slot {
    background-image: linear-gradient(145deg, #ffffff 30%, #f5cece 30%, #f5cece 50%, #ffffff 50%, #ffffff 80%, #f5cece 80%, #f5cece 100%);
    background-size: 8.72px 6.10px;
  }

  .rbc-time-gutter {
    .rbc-timeslot-group:has(.disabled-time-slot) {
      background: none;
    }

    .rbc-timeslot-group:has(.disabled-time-slots-player) {
      background: none;
    }
  }

  .noHover {
    &:hover {
      background-color: transparent !important;
    }
  }

  .rbc-time-gutter {
    z-index: 111 !important;

    .disabled-time-slot {
      background: transparent !important;
    }

    .disabled-time-slots-player {
      background: transparent !important;
    }

    .rbc-timeslot-group {
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .rbc-event {
    z-index: 11;
  }

  .disabled {
    pointer-events: initial !important;
  }

  .rbc-current-time-indicator {
    border-top: 4px dashed #2196f3;
    background-color: transparent;
    z-index: 999;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: 0 !important;
  }

  // .rbc-time-header {
  //   overflow: initial !important;
  // }
  .rbc-time-content .rbc-day-slot:nth-child(2) .rbc-current-time-indicator::before {
    $type: data-content;
    content: attr(data-content);
    width: 85px;
    padding: 6px;
    display: block;
    background: transparent;
    @if $type {
      background: #2196f3;
    }
    margin: -19px;
    z-index: 10000000000000000000000;
    font-size: 14px;
    border-radius: 7px;
    color: #fff;
    margin-left: 1px;
  }


  .rbc-header {
    font-size: 14px;
    font-weight: 500;
  }

  .rbc-time-header {
    .rbc-label {
      width: 82px !important;
      min-width: 82px !important;
      border-top: 1px solid #ddd;
    }
  }

  .rbc-time-gutter {
    .rbc-timeslot-group {
      width: 81px !important;
    }
  }

  .rbc-time-view-resources .rbc-day-slot {
    // min-width: 120px;
    // width: 120px;
    @media (min-width: 1500px) {
      width: 100% !important;
    }
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 0 !important;
    border-top: 1px solid #ddd;
  }

  .rbc-time-content {
    border-top: 1px solid #EEEEEE !important;
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid #EEEEEE !important;
  }

  .rbc-time-view {
    border: 1px solid #EEEEEE !important;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .rbc-row {
    min-height: 40px !important;
    // width: 120px !important;
    display: flex;
    align-items: center;
  }

  .rbc-time-view {
    border-left: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    background: #fff !important;
  }

  .rbc-time-content, .rbc-time-header-gutter, .rbc-time-gutter {
    background: #fff !important;
  }

  .rbc-overflowing {
    border-radius: 0 !important;
    background: transparent !important;
  }

  .rbc-event .rbc-event-content {
    font-size: 14px !important;
    top: 13% !important;
    line-height: 1.57;
    // left: 8% !important;
    justify-content: flex-start;
  }

  .rbc-timeslot-group {
    min-height: 67px !important;
  }


  .disableHover {
    .rbc-timeslot-group {
      z-index: 0;
    }
  }

  .slide-enter {
    transform: translateX(100%);
  }
  .slide-enter-active {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
  }
  .slide-exit {
    transform: translateX(0);
  }
  .slide-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
  }
}

.mainSchedual {
  .rbc-timeslot-group {
    //todo
    z-index: 1;
    transition: 0s linear background-color .05s;

    &:hover {
      // background-color: #1E88E5 !important;
      border-radius: 3px 4px 4px 3px;
    }
  }
}
.sqhashSchedual {
  .rbc-timeslot-group {
    //todo
    z-index: 1;
    transition: 0s linear background-color .05s;
    &:hover .rbc-time-slot:nth-child(-n+3) {
      // background-color: #1E88E5 !important;
    }
    &:hover .rbc-time-slot:nth-child(-n+1) {
      // border-radius: 3px 3px 0px 0px !important;
    }
    &:hover .rbc-time-slot:nth-of-type(3) {
      // border-radius: 0px 0px 3px 3px !important;
    }
  }
  
}
.player-schedule {
  border: 1px solid #0000001F;
  border-radius: 12px;
  padding: 16px 0px 16px 20px;

  .rbc-timeslot-group {
    &:hover {
      // border: 2px solid #ffffff !important;
      border-radius: 4px;
    }
  }

  .rbc-time-view {
    border-radius: 0 !important;
    border: 0 !important;
  }

  .paid, .unpaid, .cancelled, .maintenance {
    width: 100% !important;
  }

  .disableClicked {
    pointer-events: none !important;
    opacity: 1;
    background-color: #E0E0E0 !important;
    border: 2px solid #ffffff !important;
    width: 100% !important;
    border-radius: 3px 4px 4px 3px;
    color: #263238 !important;
    border-left: 0 !important;
    border-top: 0 !important;
    padding-top: 1px;
    padding-left: 14px;
  }

  .disableClicked::before {
    display: none !important;
  }
}

.sizingWidth {
  .rbc-row {
    @media (min-width: 1500px) {
      width: 100% !important;
    }
  }
}
.greaterWidth {
  .rbc-row {
    @media (min-width: 1740px) {
      width: 100% !important;
    }
    @media (min-width: 2200px) {
      width: 100% !important;
    }
  }
}
.largeWidth {
  .rbc-row {
   
    @media (min-width: 1900px) {
      width: 100% !important;
    }
  }
}
.changeWidth {
  .rbc-time-view-resources .rbc-day-slot {
    width: 100% !important;
    min-width: auto;
  }

  .rbc-row {
    width: initial !important;
  }
}

.courtPricing {
  .rbc-time-gutter {
    .rbc-time-slot {
      background-color: #ffffff !important;
    }
  }

  .rbc-header {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .rbc-agenda-view {

    td {
      border-color: #EEEEEE !important;
      font-size: 14px;
    }
  }

  .rbc-agenda-content {
    overflow: auto;
  }

  .rbc-event-content {
    p {
      color: #fff !important;
    }
  }

  .normalDay {
    width: 100% !important;

    .rbc-agenda-event-cell {
      border: 2px solid #fff !important;
      border-left: 2px solid #fff !important;
      padding: 8px 16px !important;
      border-radius: 6px;
      background: #2196F3;

      p {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    cursor: pointer;
    left: 0% !important;

  }

  .rbc-agenda-time-cell {
    padding: 8px 15px !important;
  }

  .rbc-agenda-date-cell {
    padding: 8px 16px !important;
  }

  .agendaView {
    border-top: 1px solid #EEEEEE !important;

    .rbc-agenda-event-cell {
      background-color: #26B55D !important;
      border: 2px solid #fff !important;
      border-left: 2px solid #fff !important;
      border-radius: 6px;
      padding: 8px 16px !important;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer !important;

      p {
        color: rgba(0, 0, 0, 0.6);
      }
    }

  }

  .rbc-month-row {
    .specificDay {
      cursor: pointer !important;
      width: 100% !important;
    }
  }

  .rbc-current-time-indicator {
    display: none !important;
  }

  .rbc-event {
    .rbc-event-content {
      position: initial !important;
      align-items: center !important;
    }
  }

  .specificDay {
    background-color: #26B55D !important;
    cursor: pointer !important;
    width: 100% !important;
    left: 00% !important;
    z-index: 9;
    opacity: 1;

    .chip {
      display: block;
      font-size: 14px;
      text-align: center;
      // font-family: 'Roboto';
      font-weight: 500;
    }

    .rbc-event-content {
      // align-items: flex-start !important;
      opacity: 1 !important;
      text-transform: capitalize;
      /* padding-top: 33px; */
      transform: translateY(3px);
    }
  }

  // .rbc-timeslot-group {
  //   min-height: 30px !important;
  // }
  .rbc-header {
    span {
      font-family: 'Roboto' !important;
      font-weight: 500 !important;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    font-family: 'Roboto' !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    font-size: 14px;
  }

  .rbc-agenda-table {
    border: 0 !important;
  }

  .css-17xourg-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: none !important;
  }

  .css-17xourg-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
    border-bottom: none !important;
  }

  .css-5f0fg3-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    padding-bottom: 0 !important;
  }

}

.css-woedqb-MuiPopper-root-MuiPickersPopper-root {
  width: auto !important;
}

.pricingDialog {
  .datePricker {
    .css-5z83bo-MuiInputBase-root-MuiInput-root:before, .css-5z83bo-MuiInputBase-root-MuiInput-root:after, .css-5z83bo-MuiInputBase-root-MuiInput-root:hover {
      border-bottom: none;
    }
  }
}

#launcher {
  display: none !important;
}

iframe {
  bottom: 0 !important;
}